import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import RedashIframe from '@/components/RedashIframe.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: RedashIframe,
    props: { dashboard: '9eUiFOpAkOkY463cONeYX2McgKgJfO5LyzqVszM4' },
    name: 'overview',
    meta: { key: 'overview' },
  },
  {
    path: '/activity',
    component: RedashIframe,
    props: { dashboard: 'rMi4E3B1uWTbBDtLWeHMl6hWJqZZ4zO2rJagIpDs' },
    name: 'activity',
    meta: { key: 'activity' },
  },
  {
    path: '/rules',
    component: RedashIframe,
    props: { dashboard: 'X6a0tUdURUu5idEF2pVSCijnm6LQjv2CIzoricnU' },
    name: 'rules',
    meta: { key: 'rules' },
  },
  {
    path: '/user-lookup',
    component: RedashIframe,
    props: { dashboard: 'wPd53IUBje2aucRk3anYQcEu8Kwuzc3HC7Kv4hd7' },
    name: 'user lookup',
    meta: { key: 'user lookup' },
  },
  {
    path: '/location-insights',
    component: RedashIframe,
    props: { dashboard: 'uFGNU4IVlfXVUlnACDlPHVI0srAvnjxLyUphOEHH' },
    name: 'location insights',
    meta: { key: 'location insights' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
