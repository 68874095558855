<template>
  <div
    ref="loader"
    class="absolute z-20 flex h-full w-full items-center justify-center bg-white"
    style="background-color: var(--p-content-background)"
  >
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
  </div>
  <div class="iframe-wrapper absolute h-full w-full">
    <iframe
      ref="iframe"
      class="absolute h-full w-full opacity-0 transition-opacity"
      :src
    ></iframe>
    <div class="absolute bottom-0 z-10 h-[94px] w-full bg-[#f6f8f9]"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

const props = defineProps<{
  dashboard: string
}>()

const loader = ref<any>(null)
const iframe = ref<any>(null)

const src = `https://redash.bespot.com/public/dashboards/${props.dashboard}?org_slug=default`

onMounted(() => {
  if (iframe.value) {
    iframe.value.addEventListener('load', () => {
      setTimeout(() => {
        loader.value.classList.add('hidden')
        iframe.value.classList.remove('opacity-0')
      }, 3000)
    })
  }
})
</script>
