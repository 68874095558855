import { createApp } from 'vue'

import App from '@/App.vue'
import router from '@/router.ts'
import { PrimeVue, primeVueConfig } from '@/primevue.ts'
import ToastService from 'primevue/toastservice'
import '@/style.css'

createApp(App)
  .use(router)
  .use(PrimeVue, primeVueConfig)
  .use(ToastService)
  .mount('#app')
