<template>
  <div
    class="flex flex-col gap-2 border border-t-0 border-[var(--p-menubar-border-color)] py-8 pt-[20px]"
  >
    <RouterLink
      v-for="item in menuItems"
      class="flex items-center px-5 py-3 text-lg opacity-70"
      :to="{ name: item.routeName }"
      :class="{ 'bg-slate-200': item.routeName == $route.name }"
      ><span class="mr-3 !text-xl" :class="item.icon"></span
      >{{ item.label }}</RouterLink
    >
  </div>
</template>

<script setup lang="ts">
const menuItems = [
  {
    label: 'Overview',
    icon: 'pi pi-home ',
    routeName: 'overview',
  },
  {
    label: 'Activity',
    icon: 'pi pi-wave-pulse',
    routeName: 'activity',
  },
  {
    label: 'Rules',
    icon: 'pi pi-shield',
    routeName: 'rules',
  },
  {
    label: 'User Lookup',
    icon: 'pi pi-users',
    routeName: 'user lookup',
  },
  {
    label: 'Location Insights',
    icon: 'pi pi-chart-bar',
    routeName: 'location insights',
  },
]

if (import.meta.env.VITE_SAFE_ENV === 1) {
  menuItems.push(
    {
      label: 'Geofence Simulation',
      icon: 'pi pi-map',
      routeName: 'geofence simulation',
    },
    {
      label: 'Widget Gallery',
      icon: 'pi pi-code',
      routeName: 'widget gallery',
    },
  )
}
</script>
