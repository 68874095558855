<template>
  <svg
    version="1.1"
    id="svg1"
    width="1609.1467"
    height="672.45465"
    viewBox="0 0 1609.1467 672.45465"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs1">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-162.11961,-308.45811)"
          id="path2"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath4">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-345.17821,-309.07241)"
          id="path4"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-567.55021,-206.4855)"
          id="path6"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath8">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-683.65181,-308.45811)"
          id="path8"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath10">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-821.09912,-244.5714)"
          id="path10"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath12">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-1048.5405,-179.4572)"
          id="path12"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath14">
        <path
          d="M 0,504.341 H 1206.857 V 0 H 0 Z"
          transform="translate(-1101.522,-177.00011)"
          id="path14"
        />
      </clipPath>
    </defs>
    <g id="layer-MC0">
      <path
        id="path1"
        d="m 0,0 c -16.996,0 -29.179,-4.813 -36.55,-14.437 -7.371,-9.624 -11.057,-25.903 -11.057,-48.835 v -1.229 c 0,-23.036 3.762,-39.494 11.288,-49.373 7.525,-9.882 19.835,-14.82 36.933,-14.82 15.152,0 26.439,5.552 33.864,16.663 7.421,11.107 11.133,27.155 11.133,48.144 0,21.294 -3.738,37.266 -11.211,47.915 C 26.926,-5.325 15.458,0 0,0 m 4.607,22.882 c 22.216,0 39.366,-7.654 51.448,-22.959 12.079,-15.307 18.121,-36.68 18.121,-64.116 0,-27.85 -6.143,-49.374 -18.429,-64.577 -12.286,-15.205 -29.332,-22.806 -51.14,-22.806 -11.364,0 -21.5,2.049 -30.407,6.142 -8.907,4.094 -16.178,10.187 -21.807,18.276 h -2.15 c -3.175,-12.286 -5.068,-19.404 -5.682,-21.347 H -75.25 V 90.454 h 27.643 V 32.403 c 0,-11.57 -0.513,-23.138 -1.536,-34.707 h 1.536 c 11.364,16.789 28.768,25.186 52.214,25.186"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,216.15947,261.1772)"
        clip-path="url(#clipPath2)"
      />
      <path
        id="path3"
        d="m 0,0 c -13.207,0 -23.65,-4.25 -31.329,-12.747 -7.679,-8.499 -12.235,-20.324 -13.668,-35.475 h 85.848 c -0.207,15.765 -3.84,27.743 -10.905,35.936 C 22.882,-4.097 12.9,0 0,0 m 6.91,-152.19 c -25.289,0 -45.126,7.652 -59.509,22.959 -14.385,15.304 -21.577,36.37 -21.577,63.195 0,27.028 6.704,48.553 20.118,64.577 13.412,16.022 31.533,24.034 54.365,24.034 21.193,0 38.086,-6.834 50.679,-20.502 12.593,-13.668 18.889,-32.174 18.889,-55.516 v -16.74 H -45.611 c 0.511,-19.146 5.375,-33.685 14.59,-43.615 9.213,-9.931 22.267,-14.896 39.161,-14.896 9.007,0 17.557,0.792 25.646,2.381 8.086,1.585 17.558,4.683 28.41,9.291 v -24.265 c -9.317,-3.993 -18.07,-6.81 -26.26,-8.446 -8.192,-1.637 -17.868,-2.457 -29.026,-2.457"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,460.2376,260.35813)"
        clip-path="url(#clipPath4)"
      />
      <path
        id="path5"
        d="m 0,0 c 0,-15.768 -5.888,-27.977 -17.66,-36.627 -11.775,-8.652 -28.311,-12.977 -49.605,-12.977 -22.216,0 -39.878,3.533 -52.982,10.597 v 25.186 c 18.53,-9.011 36.397,-13.515 53.597,-13.515 13.922,0 24.058,2.251 30.408,6.758 6.346,4.504 9.521,10.543 9.521,18.121 0,6.654 -3.048,12.286 -9.137,16.893 -6.093,4.607 -16.92,9.879 -32.481,15.818 -15.871,6.142 -27.029,11.389 -33.479,15.741 -6.451,4.351 -11.187,9.238 -14.205,14.666 -3.022,5.426 -4.531,12.029 -4.531,19.811 0,13.822 5.629,24.725 16.893,32.711 11.262,7.986 26.722,11.979 46.379,11.979 19.143,0 37.061,-3.789 53.751,-11.365 l -9.368,-21.96 c -17.1,7.165 -32.507,10.75 -46.226,10.75 -11.468,0 -20.171,-1.819 -26.107,-5.452 -5.939,-3.636 -8.908,-8.626 -8.908,-14.974 0,-6.142 2.559,-11.237 7.679,-15.28 5.118,-4.046 17.047,-9.652 35.782,-16.817 14.026,-5.22 24.392,-10.085 31.099,-14.588 6.704,-4.507 11.645,-9.575 14.819,-15.205 C -1.588,14.64 0,7.883 0,0"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,756.7336,397.14067)"
        clip-path="url(#clipPath6)"
      />
      <path
        id="path7"
        d="m 0,0 c -16.688,0 -28.744,-4.711 -36.166,-14.129 -7.424,-9.421 -11.237,-24.214 -11.441,-44.383 v -5.375 c 0,-22.832 3.787,-39.341 11.364,-49.527 7.576,-10.189 19.861,-15.28 36.857,-15.28 14.129,0 25.16,5.732 33.096,17.2 7.933,11.466 11.901,27.436 11.901,47.915 0,20.578 -3.968,36.319 -11.901,47.224 C 25.774,-5.452 14.537,0 0,0 m 4.607,-151.576 c -22.628,0 -40.032,8.139 -52.214,24.418 h -1.843 l 0.614,-6.297 c 0.818,-7.985 1.229,-15.101 1.229,-21.347 v -69.26 H -75.25 V 20.117 h 22.729 l 3.685,-23.035 h 1.229 c 6.55,9.214 14.128,15.868 22.729,19.964 8.6,4.094 18.529,6.143 29.793,6.143 21.908,0 38.931,-7.602 51.063,-22.805 12.131,-15.204 18.198,-36.627 18.198,-64.271 0,-27.542 -6.093,-49.042 -18.276,-64.5 -12.184,-15.461 -29.281,-23.189 -51.293,-23.189"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,911.53573,261.1772)"
        clip-path="url(#clipPath8)"
      />
      <path
        id="path9"
        d="m 0,0 c 0,-21.192 4.171,-37.294 12.517,-48.298 8.342,-11.007 20.655,-16.51 36.933,-16.51 16.072,0 28.308,5.503 36.704,16.51 8.394,11.004 12.593,27.106 12.593,48.298 0,21.193 -4.223,37.139 -12.67,47.838 C 77.631,58.535 65.269,63.887 48.99,63.887 16.329,63.887 0,42.59 0,0 m 127.312,0 c 0,-27.542 -6.964,-49.042 -20.886,-64.5 -13.925,-15.461 -33.172,-23.189 -57.743,-23.189 -15.257,0 -28.771,3.555 -40.543,10.672 -11.775,7.116 -20.836,17.328 -27.183,30.638 -6.349,13.309 -9.521,28.769 -9.521,46.379 0,27.437 6.858,48.81 20.579,64.116 13.717,15.305 32.964,22.96 57.743,22.96 23.751,0 42.616,-7.808 56.591,-23.42 C 120.324,48.042 127.312,26.822 127.312,0"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,1094.7988,346.35947)"
        clip-path="url(#clipPath10)"
      />
      <path
        id="path11"
        d="m 0,0 c 3.582,0 7.779,0.357 12.593,1.075 4.81,0.715 8.446,1.535 10.903,2.457 v -21.193 c -2.56,-1.128 -6.526,-2.227 -11.901,-3.302 -5.375,-1.075 -10.827,-1.612 -16.356,-1.612 -32.967,0 -49.45,17.353 -49.45,52.06 v 98.133 h -23.804 v 13.207 l 24.111,11.058 11.057,35.935 h 16.432 v -38.7 h 48.683 v -21.5 H -26.415 V 30.254 c 0,-9.729 2.328,-17.2 6.987,-22.422 C -14.77,2.61 -8.293,0 0,0"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,1398.0539,433.1784)"
        clip-path="url(#clipPath12)"
      />
      <path
        id="path13"
        d="m 0,0 c 0,6.757 1.663,11.952 4.991,15.587 3.325,3.633 8.063,5.452 14.205,5.452 6.244,0 11.108,-1.819 14.59,-5.452 3.48,-3.635 5.222,-8.83 5.222,-15.587 0,-6.657 -1.742,-11.902 -5.222,-15.741 -3.482,-3.84 -8.346,-5.759 -14.59,-5.759 -6.042,0 -10.75,1.896 -14.129,5.682 C 1.689,-12.032 0,-6.758 0,0"
        style="fill: #ed3558; fill-opacity: 1; fill-rule: nonzero; stroke: none"
        transform="matrix(1.3333333,0,0,-1.3333333,1468.6959,436.45453)"
        clip-path="url(#clipPath14)"
      />
    </g>
  </svg>
</template>
